import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Alert,
    Button,
    Col,
    FormLabel,
    Form,
    Table,
    FormControl,
    FormGroup,
    ProgressBar,
    Row,
    Navbar,
    Nav,
    Accordion, Card
} from "react-bootstrap";
import ReactDOM from "react-dom";
import DeleteTempStaffOverlay from "./../Overlays/DeleteTempStaffOverlay";
import ReactAvatarEditor from "react-avatar-editor";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import Spinner from "react-spinner";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import "./NewStaff.scss";
import PaginationArea from "./../Utilities/PaginationArea";
import {createStaff, createStaffFromTemp, listTempStaff, listDepartments, listLocations, addMultipleStaff} from "../../actions";

class NewStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffFiles: [],
            tempStaffFiles: [],
            staffFileUrls: [],
            tempStaffFileUrls: [],
            primaryLocation: 0,
            tempLocation: 0,
            tempDepartment: null,
            errors: { 'name': false, 'title': false, 'primaryDepartment': false },
            image: null,
            tempImage: null,
            allowZoomOut: true,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 180,
            height: 180,
            showSingleAddTab: true,
            showBatchAddTab: false,
            showTempStaffTab: false,
            activeKey: 1,
            cols:  null,
            rows: null,
            hireDate: null,
            birthDate: null,
            dataLoaded: false,
            requestPhoto: false
        };
        this.inlineError = false;
        let filter = {
            staffId: {
                type: 'wildcard',
                value: '*'
            }
        };

        this.props.listDepartments(this.props.tvClient, 'and', filter, 'asc', 1, 100);
        this.props.listLocations(this.props.tvClient).then(() => {
            let defaultValue = [];
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                defaultValue.push(this.props.locations[i].location_id);
            }
            this.setState({
                primaryLocation: defaultValue
            });
        });

        this.handleHireDateChange = this.handleHireDateChange.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);


    }

    handleHireDateChange(from) {
      this.setState({ hireDate: from });
    }
    handleBirthDateChange(to) {
      this.setState({ birthDate: to });
    } 

    dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:'image/jpeg'});
    }

    getDefaultLocations(){
         let defaultValue = [];
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
            defaultValue.push(this.props.locations[i].location_id);
        }
       return defaultValue;
    }

    newStaff(e) {
        e.preventDefault();

        var hireDate = null;
        var birthDate = null;
        var dateError = false;
        
        
        if(this.state.hireDate!==undefined && this.state.hireDate!==null){
            hireDate = dateFormat(this.state.hireDate, "shortDate");  
        }
        if(this.state.birthDate!==undefined && this.state.birthDate!==null){
            birthDate = dateFormat(this.state.birthDate, "shortDate");    
        }            

        if(this.state.hireDate===undefined){
            this.setState({ errors: {'hireDate': true }});
        } else if(this.state.birthDate===undefined){
            this.setState({ errors: {'birthDate': true }});   
        } else if(!this.staffName.value){
            this.setState({ errors: {'name': true, 'title': false, 'primaryDepartment': false }});
        } else if(!this.staffTitle.value){
            this.setState({ errors: {'name': false, 'title': true, 'primaryDepartment': false }});
        } else if(!this.state.primaryDepartment){
            this.setState({ errors: {'name': false, 'title': false, 'primaryDepartment': true }});
        } else{
            let locationField = this.state.primaryLocation;
            if(locationField===0 || locationField==="0" || locationField===undefined){
                locationField = this.getDefaultLocations();
            }
            var theStaffImage = this.state.staffFiles;
            if(this.state.image!==null){
                var baseURL = this.editor.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85);
                theStaffImage[0] = this.dataURLtoBlob(baseURL);
                    this.props.createStaff(
                        this.props.tvClient,
                        this.staffName.value,
                        this.staffTitle.value,
                        this.staffEmail.value,
                        this.staffPhone.value,
                        this.staffBio.value,
                        this.state.primaryDepartment,
                        locationField,
                        theStaffImage,
                        this.props.approver,
                        true,
                        true,
                        this.staffGoogleId.value,
                        hireDate,
                        birthDate,
                        false
                    );  
            } else{
                 this.props.createStaff(
                        this.props.tvClient,
                        this.staffName.value,
                        this.staffTitle.value,
                        this.staffEmail.value,
                        this.staffPhone.value,
                        this.staffBio.value,
                        this.state.primaryDepartment,
                        locationField,
                        theStaffImage,
                        this.props.approver,
                        true,
                        true,
                        this.staffGoogleId.value,
                        hireDate,
                        birthDate,
                        this.state.requestPhoto
                    );  
            }
        }
        
    }

    handleDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryDepartment: value
        });
    }

    handleTempDepartmentList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            tempDepartment: value
        });
    }

    handleLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            primaryLocation: value
        });
    }

    handleTempLocationList(e){
        e.preventDefault();

        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
              value.push(options[i].value);
            }
        }   

        this.setState({
            tempLocation: value
        });
    }

    handleBrowseImagesClick(e) {
        e.preventDefault();

        this.fileInput.click();
    }


    handleTempBrowseImagesClick(e) {
        e.preventDefault();

        this.tempFileInput.click();
    }

    handleFileChange(e) {
        e.preventDefault();

        this.setState({
            staffFiles: [...e.target.files],
            image: e.target.files[0],
            // Set object URLs one time so that images don't re-render when state changes
            staffFileUrls: [...e.target.files].map(staffFile => URL.createObjectURL(staffFile))
        });
    }

    handleTempFileChange(e) {
        e.preventDefault();

        this.setState({
            tempStaffFiles: [...e.target.files],
            tempImage: e.target.files[0],
            // Set object URLs one time so that images don't re-render when state changes
            tempStaffFileUrls: [...e.target.files].map(tempStaffFile => URL.createObjectURL(tempStaffFile))
        });
    }

    getProgressPercentage(bytesLoaded, bytesTotal) {
        if (bytesTotal === 0) {
            // Total set to 0 means 100 percent is complete
            return 100;
        } else if (!bytesTotal) {
            // Total not set, so 0 percent is complete
            return 0;
        }
        return Math.min((bytesLoaded / bytesTotal) * 100, 100);
    }

  getDataUrl = img => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL();
  };


  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius
      }
    });
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  submitCurrentTemp(val, e){
    var tempRow = val;
    
    
    if(this.state.tempDepartment){
        var currentRow = e.target.closest('tr');
        let locationField = this.state.tempLocation;
        if(locationField===0 || locationField==="0" || locationField===undefined){
            locationField = this.getDefaultLocations();
        }
        var theStaffImage = this.state.staffFiles;
        if(this.state.tempImage!==null){
            var baseURL = this.editor.getImageScaledToCanvas().toDataURL('image/jpeg', 0.85);
            theStaffImage[0] = this.dataURLtoBlob(baseURL);
        }
        
        this.props.createStaffFromTemp(
            this.props.tvClient,
            tempRow,
            this.state.tempDepartment,
            locationField,
            theStaffImage,
            document.getElementById('temp_staff_checkbox_'+tempRow+'').checked
        ).then(()=>{
            currentRow.remove();
        });  

    } else{
        alert('You must select a department.')
    }
  };

    refreshHandler(e){
        this.props.listTempStaff(this.props.tvClient, 1, 10);
    }

    changeListPage(page) {
        this.props.listTempStaff(this.props.tvClient, page, 10);
    }    

  rotateLeft = e => {
    e.preventDefault();

    this.setState({
      rotate: this.state.rotate - 90
    });
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    });
  };

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value);
    this.setState({ borderRadius });
  };

  handleXPosition = e => {
    const x = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, x } });
  };

  handleYPosition = e => {
    const y = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, y } });
  };

  handleWidth = e => {
    const width = parseInt(e.target.value);
    this.setState({ width });
  };

  handleHeight = e => {
    const height = parseInt(e.target.value);
    this.setState({ height });
  };

  logCallback(e) {
    // eslint-disable-next-line
    console.log("callback", e);
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    this.setState({ position });
  };

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] });
  };

    onChangeAction(e){
            var boolIntake = (this.state.requestPhoto==='true' || this.state.requestPhoto===true) ? true : false;
           this.setState({requestPhoto: !boolIntake});
    };
    onTempChangeAction(e){
        var checkboxTarget = e.target.id;
        var photoArea = e.target.id.replace('checkbox', 'photoarea');
        var photoAreaBrowse = e.target.id.replace('checkbox', 'photoareabrowse');
        document.getElementById(photoArea).style.visibility = document.getElementById(photoArea).style.visibility==='hidden' ? 'visible' : 'hidden';
        document.getElementById(photoAreaBrowse).style.visibility = document.getElementById(photoAreaBrowse).style.visibility==='hidden' ? 'visible' : 'hidden'; ;
    };

    newBatchStaff(e){
        e.preventDefault();
        this.props.addMultipleStaff(this.props.tvClient, this.state.rows).then(() => {
            this.setState({ rows: null, cols: null, dataLoaded: false });
            window.scrollTo(0,0);
        }); 
    };

    handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showSingleAddTab:true, showBatchAddTab:false, showTempStaffTab: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===2){
        this.setState({ showSingleAddTab:false, showBatchAddTab:true, showTempStaffTab: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===3){
        this.setState({ showSingleAddTab:false, showBatchAddTab:false, showTempStaffTab: true, activeKey: selectedKey });
        // Need to go get the latest list
        this.props.listTempStaff(this.props.tvClient, 1, 10);
      }
    };

    fileHandler(event) {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
            this.setState({
              cols: resp.cols,
              rows: resp.rows,
              dataLoaded: true
            });
          }
        });               
    }; 


    render() {
        return <div>
            <h1 className="page-header">
                Add Staff Member
                <small>Please enter the following staff information</small>
                <small className="backLink"><button className="simulateAnchor" onClick={() => window.history.back()} >Cancel</button></small>
                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      Single
                    </Nav.Link>   
                    <Nav.Link eventKey={2} href="#">
                      Batch
                    </Nav.Link> 
                    <Nav.Link eventKey={3} href="#">
                      List
                    </Nav.Link> 
                  </Nav>
                </Navbar>
            </h1>
            { (this.state.showSingleAddTab===true) && <Form onSubmit={this.newStaff.bind(this)} className="new-case-form">

                <legend>Staff Information</legend>

                {this.props.addStaffError && <Alert variant="danger">
                    {this.props.addStaffError.message}
                </Alert>}

                <FormGroup controlId="staffname">
                    <FormLabel>Name *</FormLabel>
                    <FormControl type="text" placeholder="John Doe" ref={ref => this.staffName = ref}/>
                    {this.state.errors.name && <Alert variant="danger">
                        Name is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="staffTitle">
                    <FormLabel>Title *</FormLabel>
                    <FormControl type="text" placeholder="Dr. / Nurse / Admin" ref={ref => this.staffTitle = ref}/>
                    {this.state.errors.title && <Alert variant="danger">
                        Title is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="staffEmail">
                    <FormLabel>Email</FormLabel>
                    <FormControl type="email" placeholder="name@hospital.com" ref={ref => this.staffEmail = ref}/>
                </FormGroup>              

                <FormGroup controlId="staffPhone">
                    <FormLabel>Phone</FormLabel>
                    <FormControl type="text" placeholder="555-555-5555" ref={ref => this.staffPhone = ref}/>
                </FormGroup>

                <FormGroup controlId="staffGoogleId">
                    <FormLabel>Staff Google Place Id</FormLabel>
                    <FormControl type="text" ref={ref => this.staffGoogleId = ref}/>
                </FormGroup>

                <FormGroup controlId="staffBio">
                    <FormLabel>Description</FormLabel>
                    <FormControl as="textarea" placeholder="Enter a brief bio here..." ref={ref => this.staffBio = ref}/>
                </FormGroup>                  

                <FormGroup controlId="primaryDepartment" className="adminDepartmentSelection">
                    <FormLabel>Choose Departments *</FormLabel>
                    <FormControl as="select" placeholder="select" onChange={this.handleDepartmentList.bind(this)} multiple>
                        {Object.values(this.props.departments).map(d => {
                            if(d.name!=='Facility' && d.name!=='Overall Staff Impression' && d.name!=='Overall Meals Impression' && d.name!=='Room Readiness'){
                                    return <option value={d.key} key={d.key}>{d.name}</option>
                            }
                        })}
                    </FormControl>                    


                    {this.state.errors.primaryDepartment && <Alert variant="danger">
                        A primary department is required.
                    </Alert>}
                </FormGroup>

                <FormGroup controlId="primaryLocation" className="adminLocationSelection">
                    <FormLabel>Choose Locations *</FormLabel>
                    <FormControl as="select" placeholder="select" defaultValue={[0]} onChange={this.handleLocationList.bind(this)} multiple>
                        {(!this.props.isLocationOnly) && <option value="0">All Locations</option> }
                        {Object.values(this.props.locations).map(d => {
                            if(this.props.isLocationOnly){
                                if(this.props.roleLocations!==undefined){
                                    for(var i=0; i<this.props.roleLocations.length;i++){
                                        if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                        }
                                    }
                                } else{
                                    return  <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                }
                            } else{
                                return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                            }

                        })}
                    </FormControl>                    


                    {this.state.errors.primaryLocation && <Alert variant="danger">
                        A location is required.
                    </Alert>}
                </FormGroup>

               <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Optional Attributes &#8628;
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <FormGroup controlId="hireDate" className="adminLocationSelection">
                        <p><b>HIRE DATE (MM/DD/YYYY)</b></p>
                        <div>
                        <DayPickerInput formatDate={formatDate} parseDate={parseDate} placeholder={`${formatDate(new Date())}`} id="hireDate" name="hireDate" onDayChange={this.handleHireDateChange}
                            dayPickerProps={{
                              selectedDays: this.state.selectedDay,
                              disabledDays: {
                                after: new Date(),
                              },
                            }} />
                            </div>
                        </FormGroup>
                        {this.state.errors.hireDate && <Alert variant="danger">
                        Your date is not properly formatted
                        </Alert>}
                        <FormGroup controlId="birthDate" className="adminLocationSelection">
                        <p><b>BIRTH DATE (MM/DD/YYYY)</b></p>
                        <div>
                        <DayPickerInput formatDate={formatDate} parseDate={parseDate} placeholder={`${formatDate(new Date())}`} id="birthDate" name="birthDate" onDayChange={this.handleBirthDateChange}
                            dayPickerProps={{
                              selectedDays: this.state.selectedDay,
                              disabledDays: {
                                after: new Date(),
                              },
                            }} />
                            </div>
                        </FormGroup>
                        {this.state.errors.birthDate && <Alert variant="danger">
                        Your date is not properly formatted
                        </Alert>}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  </Accordion>
                  <br/>
                    <FormGroup controlId="photoRequest">
                        <FormLabel>Request Staff Photo by Email: </FormLabel>
                            &nbsp; &nbsp; <input type="checkbox" checked={(this.state.requestPhoto==='true'||this.state.requestPhoto===true) ? true : false} onChange={this.onChangeAction.bind(this)}/>
                    </FormGroup>


                 {!this.state.requestPhoto && <div>
                <legend>Upload Photo</legend>
                <p>(Headshot - 180px x 180px)</p>

                <Row>
                {
                    this.state.staffFileUrls.map(url => {
                        return <Col className="thumbnail-col" key={url}>

        <ReactAvatarEditor
            ref={this.setEditorRef}
            scale={parseFloat(this.state.scale)}
            width={this.state.width}
            height={this.state.height}
            position={this.state.position}
            onPositionChange={this.handlePositionChange}
            rotate={parseFloat(this.state.rotate)}
            borderRadius={this.state.width / (100 / this.state.borderRadius)}
            onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
            onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
            onImageReady={this.logCallback.bind(this, "onImageReady")}
            image={url}
            className="editor-canvas"
          /><br/>
          Zoom:
        <input
          name="scale"
          type="range"
          onChange={this.handleScale}
          min={this.state.allowZoomOut ? "0.1" : "1"}
          max="2"
          step="0.01"
          defaultValue="1"
        />
        <br />
        <p>Rotate:
        <button onClick={this.rotateLeft}>Left</button>
        <button onClick={this.rotateRight}>Right</button></p>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box">
                    {this.props.addingStaff ? (
                        <ProgressBar now={this.getProgressPercentage(this.props.bytesLoaded, this.props.bytesTotal)}/>
                    ) : (
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleFileChange.bind(this)} ref={ref => this.fileInput = ref}/>
                            <a href="#" onClick={this.handleBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                    )}

                </div>
                </div> }
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingStaff} className="float-right">
                        Add
                    </Button>
                </FormGroup>
            </Form> }

            { (this.state.showBatchAddTab===true) &&  <Form onSubmit={this.newBatchStaff.bind(this)} className="case-list-container">

                <legend>Batch Addition <small>(Download the <a href="/WelliQ-AddBatchStaff.xlsx">Excel Template</a>)</small></legend>
                <p>Rows Processed: {this.props.peopleAdded} / {this.props.totalPeople>0 ? this.props.totalPeople : '-'}</p>

                <FormGroup controlId="locationName">
                    <FormLabel>Upload *</FormLabel>
                    <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                </FormGroup>
                <FormGroup className="submit-buttons">
                    <Button type="submit" disabled={this.props.addingMultipleStaff} className="pull-left">
                        {this.props.addingMultipleStaff ? 'Adding' : 'Add'}
                    </Button>
                    {this.props.addingMultipleStaff && <Spinner/>}
                </FormGroup>
                {this.state.dataLoaded && 
                    <div className="case-list-container">
                        <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                    </div>
                }


            </Form>

            }

            { (this.state.showTempStaffTab===true) &&  <div className="case-list-container">
                <h3>Temporary Staff List</h3>
                <p>Complete each staff member one at a time from the batch upload</p>
                {this.props.addTempStaff && <Spinner/>}
                {!this.props.tempStaffLoading && <Table className="case-list sortable">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Locations</th>
                        <th>Departments</th>
                        <th>Photo</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.tempStaff.map((c,i) => {
                            return <tr id={'temp_staff_' + c.staff_id} key={c.staff_id} className={(i % 2)===0 ? "" : "altRowColor"}>
                                <td>{c.name}</td>
                                <td>
                                    <FormControl as="select" placeholder="select" defaultValue={[0]} onChange={this.handleTempLocationList.bind(this)} multiple>
                                        <option value="0">All Locations</option>
                                        {Object.values(this.props.locations).map(d => {
                                            return <option value={d.location_id} key={d.location_id}>{d.name}</option>
                                        })}
                                    </FormControl> 
                                </td>
                                <td>
                                    <FormControl as="select" placeholder="select" onChange={this.handleTempDepartmentList.bind(this)} multiple>
                                        {Object.values(this.props.departments).map(d => {
                                            if(d.name!=='Facility'){
                                                    return <option value={d.key} key={d.key}>{d.name}</option>
                                            }
                                        })}
                                    </FormControl> 
                                </td>

                                <td>

                            <FormLabel>Request Staff Photo by Email: </FormLabel>
                            &nbsp; &nbsp; <input type="checkbox" id={'temp_staff_checkbox_' + c.staff_id} onChange={this.onTempChangeAction.bind(this)}/>
                    
                 <Row id={'temp_staff_photoarea_' + c.staff_id}>
                {
                    this.state.tempStaffFileUrls.map(url => {
                        return <Col className="thumbnail-col" key={url}>
                            <ReactAvatarEditor
                                ref={this.setEditorRef}
                                scale={parseFloat(this.state.scale)}
                                width={this.state.width}
                                height={this.state.height}
                                position={this.state.position}
                                onPositionChange={this.handlePositionChange}
                                rotate={parseFloat(this.state.rotate)}
                                borderRadius={this.state.width / (100 / this.state.borderRadius)}
                                onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
                                onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
                                onImageReady={this.logCallback.bind(this, "onImageReady")}
                                image={url}
                                className="editor-canvas"
                              /><br/>
                              Zoom:
                            <input
                              name="scale"
                              type="range"
                              onChange={this.handleScale}
                              min={this.state.allowZoomOut ? "0.1" : "1"}
                              max="2"
                              step="0.01"
                              defaultValue="1"
                            />
                            <br />
                            <p>Rotate:
                            <button onClick={this.rotateLeft}>Left</button>
                            <button onClick={this.rotateRight}>Right</button></p>
                        </Col>
                    })
                }
                </Row>

                <div className="progress-box" id={'temp_staff_photoareabrowse_' + c.staff_id}>
                        <div>
                            <input type="file" multiple accept="image/*" style={{display: 'none'}}
                                   onChange={this.handleTempFileChange.bind(this)} ref={ref => this.tempFileInput = ref}/>
                            <a href="#" onClick={this.handleTempBrowseImagesClick.bind(this)}>Browse Images</a>
                        </div>
                </div>


                                </td>
                                <td>
                                    <button className="btn btn-primary" onClick={this.submitCurrentTemp.bind(this,c.staff_id)}>Add</button><br/>
                                    {c.staff_id && <DeleteTempStaffOverlay staffDetail={c} parentRefresh={this.refreshHandler.bind(this)}/>}
                                </td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table>}
                {this.props.tempStaffLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.tempStaffPagination.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.tempStaffPagination.num_pages} items={this.props.tempStaffPaginationItems} />
                </div>
            }

        </div>
    }
}

const mapStateToProps = state => {
    return {
        addingStaff: state.addStaff.addingStaff,
        addStaffError: state.addStaff.addStaffError,
        bytesLoaded: state.addStaff.bytesLoaded,
        bytesTotal: state.addStaff.bytesTotal,
        addTempStaff: state.tempStaffAdd.addingStaff,
        tvClient: state.login.tvClient,
        approver: state.login.user.user_id,
        departments: state.deptList.departments,
        locations: state.locationList.locations,
        staffAdded: state.addMultipleStaff.staffAdded,
        totalStaff: state.addMultipleStaff.totalStaff,
        tempStaff: state.tempStaffList.staff,
        tempStaffPagination: state.tempStaffList.paginationInfo,
        tempStaffPaginationItems: state.tempStaffList.paginationInfo.pageList,
        tempStaffLoading: state.tempStaffList.loading,
        addingMultipleStaff: state.addMultipleStaff.addingMultipleStaff,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listDepartments: (...params) => dispatch(listDepartments(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
        listTempStaff: (...params) => dispatch(listTempStaff(...params)),
        createStaff: (...params) => dispatch(createStaff(...params)),
        addMultipleStaff: (...params) => dispatch(addMultipleStaff(...params)),
        createStaffFromTemp: (...params) => dispatch(createStaffFromTemp(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStaff);
