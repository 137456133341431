import React, {Component} from "react";
import {
    Alert,
    Table, 
    Dropdown,
    Navbar,
    Nav,
    FormLabel
} from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import {listSurveys, listLocations, viewSurvey, findWinner, saveWinner} from "../../actions";
import "./SurveyList.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import PaginationArea from "./../Utilities/PaginationArea";
import SurveyStatus from "./../Utilities/SurveyStatus";
import ListNames from "./../Utilities/ListNames";
import dateFormat from "dateformat";
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import {formatDate} from 'react-day-picker/moment';
import queryString from 'query-string';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

class SurveyList extends Component {
    constructor(props) {
        super(props);

        let activeTab = 1;
        let showSurveyListTab=true;
        let showPrizeDrawTab=false;

        if(props.location && props.location.query && props.location.query.active!==null && props.location.query.active !== undefined){
            activeTab = parseInt(props.location.query.active, 10);
            if(activeTab===2){
                showSurveyListTab=false;  showPrizeDrawTab=true;
            }
        }

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.drawTheWinner = this.drawTheWinner.bind(this);
        this.props.listLocations(this.props.tvClient).then(()=>{
             if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName, raffleLocationFilter: newLocation, currentRaffleFilterLocation: newLocationName }, ()=>{
                                this.changeListPage(1);
                            });     
                        }
                    } 
                } 
        });
        
        this.state = {
            perpage: 10,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            currentFilterName: '',
            prizeFrom: undefined,
            prizeTo: undefined,
            currentFilterName: ' Filter Surveys ',
            currentFilterLocation: ' All Locations ',
            currentRaffleFilterLocation: ' All Locations ',
            showSurveyList: showSurveyListTab,
            showPrizeDraw: showPrizeDrawTab,
            activeKey: activeTab,
            winner: null,
            locationFilter: 0,
            raffleLocationFilter: 0,
            winnerSaved: false,
            showRaffleLocationError: false
        };
    }

  drawTheWinner(ev){
        this.props.findWinner(this.props.tvClient, dateFormat(this.state.prizeFrom, "shortDate"), dateFormat(this.state.prizeTo, "shortDate"), this.state.raffleLocationFilter).then(()=>{
            this.setState({'winner': this.props.winner, winnerSaved: false, showRaffleLocationError: false });
        })        
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ "prizeFrom": from });
  }
  handleToChange(to) {
    this.setState({ "prizeTo": to });
  }

    componentDidMount() {
        window.scrollTo(0,0);
        const queryStringValues = queryString.parse(this.props.location.search);
        if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
            if(queryStringValues.refresh==1){
                this.changeListPage(1);
            } else {
                if(this.props.surveys.length===0){
                    this.changeListPage(1);
                }
            }
        } else{
            if(!this.props.isLocationOnly) { this.changeListPage(1); }
        }
    }

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id);
    }

    saveWinner(id){
        this.props.saveWinner(this.props.tvClient, id).then(()=>{
         this.setState({ winnerSaved: true });
         this.changeListPage(1);
        });
    }

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        //this.props.listStaff(this.props.tvClient, 'and', this.state.primaryDepartment, this.state.sortDirection, 1, 10, 0)
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        this.props.listSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0);
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.props.listSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.props.locations[i].location_id).then(()=>{
                this.setState({ locationFilter: this.props.locations[i].location_id, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });     
            });
            break;
          }
        }
      }
    }

    handleRaffleLocationFilter(selectedKey){
      if(parseInt(selectedKey)!==0){
            for (var i = this.props.locations.length - 1; i >= 0; i--) {
              if(this.props.locations[i].location_id===parseInt(selectedKey)){
                this.setState({ raffleLocationFilter: this.props.locations[i].location_id, currentRaffleFilterLocation: ' ' + this.props.locations[i].name + ' ' });     
                break;
              }
            }
        }
        else{
            this.setState({ raffleLocationFilter: 0, currentRaffleFilterLocation: ' Choose Location ' });     
        }
    }    

     handleSelect(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ showSurveyList: true, showPrizeDraw: false, activeKey: selectedKey });
      } else if(parseInt(selectedKey)===2){
        this.setState({ showSurveyList: false, showPrizeDraw: true, activeKey: selectedKey });
      } 
    }    

    changeListPage(page) {
        this.props.listSurveys(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter);
    }

    toggleSortDirection(key) {
        return () => {
            let newSortDirection = 'asc';
            if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
                newSortDirection = 'desc';
            }

            this.setState({
                sortKey: key,
                sortDirection: newSortDirection
            }, () => this.changeListPage(this.props.paginationInfo.current_page));
        };
    }

    sortIndicator(key) {
        if (this.state.sortKey === key && this.state.sortDirection === 'asc') {
            return <FontAwesomeIcon icon={faArrowUp}/>;
        } else if (this.state.sortKey === key && this.state.sortDirection === 'desc') {
            return <FontAwesomeIcon icon={faArrowDown}/>;
        }
    }

    dueBeforeChange(event) {
        this.setState({
            dueBefore: event.target.value || null
        }, () => this.changeListPage(this.props.paginationInfo.current_page));
    }

    tableHeader(sortKey, label) {
        return <th className="sortable" onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    handleFilter(selectedKey) {
      if(parseInt(selectedKey)===1){
        this.setState({ filter: 'completed', currentFilter: selectedKey, currentFilterName: ' Completed ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'completed', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==2){
        this.setState({ filter: 'inprogress', currentFilter: selectedKey, currentFilterName: ' In Progress ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'inprogress', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==3){
        this.setState({ filter: 'anonymous', currentFilter: selectedKey, currentFilterName: ' Anonymous ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'anonymous', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==4){
        this.setState({ filter: 'identified', currentFilter: selectedKey, currentFilterName: ' Identified ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'identified', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==5){
        this.setState({ filter: 'hasstaff', currentFilter: selectedKey, currentFilterName: ' Staff Inclusive ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'hasstaff', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==6){
        this.setState({ filter: 'departmentonly', currentFilter: selectedKey, currentFilterName: ' Departmental ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'departmentonly', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==7){
        this.setState({ filter: 'orgonly', currentFilter: selectedKey, currentFilterName: ' Organizational ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'orgonly', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==9){
        this.setState({ filter: 'patientonly', currentFilter: selectedKey, currentFilterName: ' Patient ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'patientonly', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      }  else if(selectedKey==11){
        this.setState({ filter: 'peertopeer', currentFilter: selectedKey, currentFilterName: ' Peer-to-Peer ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'peertopeer', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      } else if(selectedKey==10){
        this.setState({ filter: 'employeronly', currentFilter: selectedKey, currentFilterName: ' Employer ' });
        this.props.listSurveys(this.props.tvClient, 'and', 'employeronly', this.state.sort, 1, this.state.perpage, this.state.locationFilter);
      }

      if(selectedKey==8){
        this.setState({ filter: '', currentFilter: selectedKey }, () => {
            this.changeListPage(1);    
        });
        
      }
    }



    render() {
        return <div>
            <h1 className="page-header">
                Patient Surveys
                <Navbar className="submenuNav" variant="light" expand="lg">
                  <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect.bind(this)}>
                    <Nav.Link eventKey={1} href="#">
                      View List
                    </Nav.Link>   
                    {(!this.props.isSurveysReportsOnly) && <Nav.Link eventKey={2} href="#">
                      Prize Drawing
                    </Nav.Link> }
                  </Nav>
                </Navbar>
            </h1>

            {this.state.showSurveyList && <div className="case-list-container">
                {this.props.listError && <Alert variant="danger">{this.props.listError.message}</Alert>}
                {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown
                  title='Filter Surveys'
                  id='filterSurvey'
                  drop="down" className="float-left" onSelect={this.handleFilter.bind(this)}
                >
                  <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                    {this.state.currentFilterName}
                  </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1">All Completed</Dropdown.Item>
                      <Dropdown.Item eventKey="2">All In Progress</Dropdown.Item>
                      <Dropdown.Item eventKey="5">Contains Staff</Dropdown.Item>
                      <Dropdown.Item eventKey="9">Patient Only</Dropdown.Item>
                      <Dropdown.Item eventKey="11">Peer-to-Peer Only</Dropdown.Item>
                      <Dropdown.Item eventKey="10">Employer Only</Dropdown.Item>
                      <Dropdown.Divider/>
                      <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown> }
                
                {(this.props.locations) && <div className="customLocationRange float-right mt-0 mr-5">
                        <FormLabel>Filter by Location:</FormLabel>
                       <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                    {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item>}
                      {Object.values(this.props.locations).map(d => {
                        if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                            if(this.props.roleLocations!==undefined){
                                for(var i=0; i<this.props.roleLocations.length;i++){
                                    if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                        return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                    }
                                }
                            } else{
                                return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                            }
                        } else{
                            return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                        }
                        
                      })}
                        </Dropdown.Menu>
                    </Dropdown>
            </div> }  

                {!this.props.surveysLoading && <div className="case-list-summary clearBoth">Page {this.props.paginationInfo.current_page}{' '}of {this.props.paginationInfo.num_pages}: {this.props.surveys.length>0 ? this.props.surveys[0].full_count : '0'}
                    {' '}<b>{this.state.currentFilterName}</b>total survey{this.props.surveys.length === 1 ? '' : '\'s'} for <b>{this.state.currentFilterLocation}</b>
                </div>}

                {  this.props.surveys && <Table className="case-list sortable">
                    <thead>
                    <tr>
                        <th>Survey Type</th>
                        <th>Respondent</th>
                        <th>Overall Score</th>
                        <th>Departments</th>
                        <th>Staff</th>
                        <th>Status</th>
                        <th>Location</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.surveys.map((c, i) => {
                            return <tr className={(i % 2)===0 ? "surveyRow" : "surveyRow altRowColor"} onClick={()=> this.viewSurvey(c.survey_id)} key={c.survey_id}>
                                <td>{(c.url_type==='EMP') ? 'Peer-to-Peer' : c.url_type}</td>
                                <td>{(c.url_type==='EMP' && c.survey_data.anonymous!==true) ? c.name : c.respondent_type}</td>
                                <td>{(c.survey_data.nps!==undefined && c.survey_data.nps!==null) && <SurveyStatus status={c.survey_data.nps}/>}{(c.survey_data.nps===undefined) && <p>N/A</p>}</td>
                                <td><ListNames items={c.survey_data.departments}/></td>
                                <td><ListNames items={c.survey_data.staff}/></td>
                                <td>{c.completed ? 'Complete' : 'In Progress'}</td>
                                <td>{c.locationname!==null ? c.locationname : 'N/A'}</td>
                                <td>{dateFormat(c.created, "shortDate")}</td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table> }
                {this.props.surveysLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />

            </div>
        }
        { this.state.showPrizeDraw && <div className="case-list-container">
            {!this.props.isViewOnly && <div>
              <p className="prizeDrawTitle">Select the range to draw a prize for:</p>
              <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
          dayPickerProps={{
            selectedDays: this.state.selectedDay,
            disabledDays: {
              after: new Date(),
            },
          }} />

              <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
          dayPickerProps={{
            selectedDays: this.state.selectedDay,
            disabledDays: {
              after: new Date(),
              before: this.state.prizeFrom
            },
          }} />


           {(this.props.locations) && <div className="customLocationRange float-left mt-0 mr-5">
                       <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleRaffleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentRaffleFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu> 
                    {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>Choose Location</Dropdown.Item>}
                      {Object.values(this.props.locations).map(d => {
                        if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                            if(this.props.roleLocations!==undefined){
                                for(var i=0; i<this.props.roleLocations.length;i++){
                                    if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                        return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                    }
                                }
                            } else{
                                return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                            }
                        } else{
                            return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                        }
                        
                      })}
                        </Dropdown.Menu>
                    </Dropdown>
            </div> }  

          <button className="btn btn-primary" onClick={this.drawTheWinner}>{this.props.searchingWinner ? 'Searching' : 'Draw for Winner'}</button>
          {this.state.showRaffleLocationError && <p className='error'>You must select your specific location</p>}

          {this.state.winner && <div className="case-list-container potentialWinner">
            {(this.props.winner && this.state.winner.person) && <div><div>
                <p>Your winner is: <b>{this.props.winner.person}</b></p>
                {this.state.winnerSaved ? <p>Saved!</p> : <button className="simulateAnchor" onClick={()=> this.saveWinner(this.props.winner.ticket_id)}>Save Winner</button>}
            </div><div>
                <p><b>{this.props.winner.person}</b> gave a score of <b>{this.props.winner.survey_data.nps}/5</b> on <b>{dateFormat(this.props.winner.updated, "shortDate")}</b> from the <b>{this.props.winner.location_name}</b></p>
            </div></div>}
            {!this.state.winner.person && <p>No winner could be found for that date range</p>}
          </div>
            }
            </div>}
            <hr/>
            <div>
            <h3 className="innerTitleWinners">Previous Winners</h3>
                {  this.props.winners && <Table className="case-list">
                    <thead>
                    <tr>
                        <th>Location</th>
                        <th>Survey Date</th>
                        <th>Date Drawn</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.winners.map(c => {
                            return <tr className="surveyRow" onClick={()=> this.viewSurvey(c.survey_id)} key={c.survey_id}>
                                <td>{c.name}</td>
                                <td>{dateFormat(c.updated,"shortDate")}</td>
                                <td>{dateFormat(c.win_date, "shortDate")}</td>
                                <td>View Details</td>
                            </tr>;
                        })
                    }
                    </tbody>
                    </Table>}

            </div>
            </div>}
            <footer className="adminFooter">&copy; Well iQ {new Date().getFullYear()} | <a href="mailto:support@welliq.org">support@welliq.org</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.welliq.org/privacy.html">Privacy Policy</a></footer> 
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        surveys: state.surveyList.surveys,
        pagination: state.surveyList.paginationInfo,
        surveysLoading: state.surveyList.loading,
        winners: state.surveyList.winners,
        paginationInfo: state.surveyList.paginationInfo,
        listError: state.surveyList.error,
        winner: state.theWinner.winner,
        theWinner: state.theWinner,
        paginationItems: state.surveyList.paginationInfo.pageList,
        searchingWinner: state.theWinner.winnerLoading,
        locations: state.locationList.locations,
        isViewOnly: state.login.user && state.login.user.attributes.role === 'viewonly',
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listSurveys: (...params) => dispatch(listSurveys(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        findWinner: (...params) => dispatch(findWinner(...params)),
        saveWinner: (...params) => dispatch(saveWinner(...params)),
        listLocations: (...params) => dispatch(listLocations(...params)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyList));
