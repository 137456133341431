import React, {Component} from "react";
import {
    Table,
    Dropdown
} from "react-bootstrap";
import { viewSurvey, listLocationMappingGroups, findStaffByNameOnly, listAllStaffFeedbackReport, listLocations } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux"; 
import PaginationArea from "./../../Utilities/PaginationArea";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import ExportCSV from './../../Utilities/ExportCSV';
import ExportWrittenStaffFeedbackCSV from './../../Utilities/ExportWrittenStaffFeedbackCSV';
import StaffProfileMenuItem from './../../Utilities/StaffProfileMenuItem.js';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';
import queryString from 'query-string';
const AsyncTypeahead = asyncContainer(Typeahead);


class WrittenStaffFeedbackReport extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            perpage: 20,
            filter: '',
            sort: null,
            sortDirection: 'desc',
            currentFilter: 8,
            activeKey: 1,
            dateFilter: 'thismonth',
            locationFilter: 0,
            currentFilterName: '  This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
            currentFilterLocation: ' All Locations ',
            showCustomDate: false,
            showLocations: true,
            reportFrom: null,
            reportTo: null,
            searchoptions: [],
            isLoading:false,
            businessLine: null
        };

          this.selectStaffFromSearch = this.selectStaffFromSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);  

        this.props.listLocations(this.props.tvClient).then(()=>{
          if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
                                this.changeListPage(1);
                            });     
                        }
                    } 
                } 
        });     
    }   


    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id, 'reports/stafffeedback', this.state.currentFilter, this.state.locationFilter);
    }    

    runReport(){
      this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      //this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
      this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine);
    } 

    handleLocationFilter(selectedKey){
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        if(this.state.dateFilter==='custom'){
            this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);    
        } else{
            this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, 0, this.state.dateFilter, null, null, this.state.businessLine);    
        }
        
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            if(this.state.dateFilter==='custom'){
                this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);    
            } else{
                this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, null, null, this.state.businessLine);    
            }
            
            break;
          }
        }
        
      }
    }

    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);
        });
      } else if(selectedKey==2){
        this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);
        });
      } else if(selectedKey==3){
        this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null,null, this.state.businessLine);
        });
      } else if(selectedKey==4){
        this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);
        });
      } else if(selectedKey==5){
        this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: selectedKey }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);
        });
        
      }
    }

  handleLocationMappingFilter(selectedKey){
      if(selectedKey.toString()==='-1'){
              this.setState({ businessLine: null}, ()=>{
                if(this.state.dateFilter==='custom'){
                  this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine);
                } else{
                  this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.businessLine);
                }
              });

      } else{

          for(var i=0; i<this.props.locationMapping.length; i++){
            if(parseInt(i)===parseInt(selectedKey)){
              this.setState({ businessLine: this.props.locationMapping[i].location_resource_alt}, ()=>{
                if(this.state.filter==='custom'){
                    this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),this.state.businessLine);
                } else{
                    this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null,this.state.businessLine);
                }
              });
              break;
            }
          }
      }
      
    }


    handleSearch(query) {
          this.setState({isLoading: true});
          this.props.findStaffByNameOnly(this.props.tvClient.accessToken, query).then((staff)=>{
            this.setState({isLoading: false, searchoptions: staff });
          })
    }

    removeStaffFilter(){
      this.typeahead.getInstance().clear();
      this.setState({filter: ''}, () => {
        this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);      
      });
    }

    selectStaffFromSearch(person) {
      if(person[0]!==undefined){
        this.setState({ filter: person[0].staff_id }, () => {
          this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);      
        });
      }
    } 

    componentDidMount() {

        window.scrollTo(0,0);
        const queryStringValues = queryString.parse(this.props.location.search);
        if(queryStringValues.refresh!==null && queryStringValues.refresh!==undefined){
            if(queryStringValues.refresh==1){
                this.changeListPage(1);
            } else {
                if(this.props.staffFeedbackData.length===0){
                  if(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly){
                    this.changeListPage(1);
                  }
                } else{
                  
                  if(queryStringValues.dateFilter==1){
                    this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' All Time ' });
                  } else if(queryStringValues.dateFilter==2){
                    this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' });
                  } else if(queryStringValues.dateFilter==3){
                    this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' });
                  } else if(queryStringValues.dateFilter==4){
                    this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' });
                  } else if(queryStringValues.dateFilter==5){
                    this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: queryStringValues.dateFilter, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' });
                  } else if(queryStringValues.dateFilter==8){
                    this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: queryStringValues.dateFilter });
                  }

                  if(parseInt(queryStringValues.locationFilter)===0){
                    this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
                  } else{
                      for (var i = this.props.locations.length - 1; i >= 0; i--) {
                        if(this.props.locations[i].location_id===parseInt(queryStringValues.locationFilter)){
                          this.setState({ locationFilter: queryStringValues.locationFilter, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });                        
                          break;
                        }
                      }
                  }

                }
            }
        } else{
          if(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly){
            this.changeListPage(1);    
          }
        }

        //this.changeListPage(1);
        this.props.listLocationMappingGroups(this.props.accessToken);
    }

    changeListPage(page) {
        if(this.state.dateFilter==='custom'){
            this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.businessLine);
        } else{
            this.props.listAllStaffFeedbackReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null, this.state.businessLine);            
        }
        
    }

    render() {
       return <div>
            <h1 className="page-header">
                Written Staff Feedback

            <div className="row float-right filterRow writtenStaffReportContainer">
                  <div>
                                    {(this.props.locationMapping!==null && this.props.locationMapping.length>0) && <div className="float-right customLocationRange extraRightMargin">
                            <label htmlFor="filterLineSurvey">Business Line:</label>
                            <Dropdown
                              title='Choose Business Line'
                              id='filterLineSurvey'
                              drop="down" onSelect={this.handleLocationMappingFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                 {this.state.businessLine!==null ? this.state.businessLine : ' Choose Business Line '}
                              </Dropdown.Toggle>
                              <Dropdown.Menu> 
                              <Dropdown.Item eventKey={-1}>All Business Lines</Dropdown.Item>
                                {Object.values(this.props.locationMapping).map((d,counter) => {
                                return  <Dropdown.Item eventKey={counter} key={d.location_resource_alt}>{d.location_resource_alt}</Dropdown.Item>
                              })}
                                </Dropdown.Menu>
                            </Dropdown>
                    </div> }         
              </div>
              <div>

                    <div className="float-right customLocationRange extraRightMargin">
                      <label htmlFor="filterSurvey">Choose a Date:</label>

                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>  
                          <Dropdown.Item eventKey="1">All Time</Dropdown.Item>
                          <Dropdown.Item eventKey="2">This Month</Dropdown.Item>
                          <Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
                          <Dropdown.Item eventKey="4">YTD</Dropdown.Item>
                          <Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
                          <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                          <Dropdown.Divider/>
                          <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                  </div>


              <div>
                    {(this.state.showLocations && this.props.locations) && <div className="customLocationRange">
                          <label htmlFor="filterSurvey">Choose a Location:</label>
                            <Dropdown
                              title='Choose a Location'
                              id='filterSurvey'
                              drop="down" onSelect={this.handleLocationFilter.bind(this)}
                            >
                              <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                                {this.state.currentFilterLocation}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>  
                      
                            {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
                              {Object.values(this.props.locations).map(d => {
                                if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                                      if(this.props.roleLocations!==undefined){
                                          for(var i=0; i<this.props.roleLocations.length;i++){
                                              if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                                  return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                              }
                                          }
                                      } else{
                                          return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                      }
                                  } else{
                                      return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                  }
                              })}
                              </Dropdown.Menu>
                            </Dropdown>
                    </div> }                 

              </div>
              
              
            </div>
              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
              </div>
            }


            </h1>
            <div className="case-list-container searchEmployeeContainer">
            <div className="col-md-3 float-right">

                      <div className="customLocationRange searchEmployeeBoxHolder">
                        <label htmlFor="searchByNameInput">Search Results By Name:{this.state.filter!=='' && <a className="removeStaffFilter" onClick={this.removeStaffFilter.bind(this)}><h3> (Reset)</h3></a>}</label>
                        
                     <AsyncTypeahead
                     id="searchByNameInput"
                      isLoading={this.state.isLoading}
                       labelKey="name"
                      onSearch={this.handleSearch}
                      onChange={this.selectStaffFromSearch}
                      options={this.state.searchoptions} 
                      placeholder="Search by Name"
                      ref={(typeahead) => this.typeahead = typeahead}
                      renderMenuItemChildren={(option, props) => (
                        <StaffProfileMenuItem key={option.staff_id} user={option} />
                      )}
                    /> 

                      </div>
              </div>
            </div>
            

            <div className="case-list-container">
             <p className="stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> {(this.state.businessLine!==null && this.state.businessLine!=='') ? ' in ' + this.state.businessLine + ' ' : ''} at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
                {  this.props.staffFeedbackData && <Table className="case-list sortable reportBadgeContainer">
                    <thead>
                    <tr className="reportBadgeContainer table-headings">
                        <th scope="col">Name</th>
                        <th scope="col">Feedback</th>
                        <th scope="col">Badge Count</th>
                        <th scope="col">Survey Score</th>
                        <th scope="col">Survey Date</th>
                        <th scope="col" className="text-center">Survey Link</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.staffFeedbackData.map((c,index) => {
                            return <tr className="surveyRow statDeptContainer reportBadgeContainer" onClick={()=> this.viewSurvey(c.survey_id)} key={index}>
                                <td >{c.response.name}</td>
                                <td>{c.response.feedback}</td>
                                <td className="text-center">{c.response.badges!==undefined ? c.response.badges.length : '0'}</td>
                                <td className="text-center">{c.nps!==null ? c.nps : 'N/A'}</td>
                                <td className="text-center">{dateFormat(c.created, "shortDate")}</td>
                                <td className="text-center totalTdValue">View Survey</td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table> 
              
                }
                <div className="float-right">
                  <ExportCSV csvData={this.props.exportStaffFeedbackData} fileName='StaffFeedback.csv' />
                  <ExportWrittenStaffFeedbackCSV businessLine={this.state.businessLine} toDate={this.state.toDate} fromDate={this.state.fromDate} dateFilter={this.state.dateFilter} filter={this.state.filter} filterType={this.state.filterType} locationId={this.state.locationFilter} locationName={this.state.currentFilterLocation} token={this.props.tvClient.accessToken} />
              </div>
              {(!this.props.staffFeedbackDataLoading && this.props.staffFeedbackData.length===0) && <p className="text-center">No Results Found</p>}
                {this.props.staffFeedbackDataLoading && <Spinner/>}
                <PaginationArea currentPage={this.props.paginationInfo.current_page} parentRefresh={this.changeListPage.bind(this)} numberOfPages={this.props.paginationInfo.num_pages} items={this.props.paginationItems} />          
            </div>    

        </div> 
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        staffFeedbackDataLoading: state.staffFeedbackData.loading,
        staffFeedbackData: state.staffFeedbackData.staffFeedbackData,
        exportStaffFeedbackData: state.staffFeedbackData.exportStaffFeedbackData,
        paginationInfo: state.staffFeedbackData.paginationInfo,
        paginationItems: state.staffFeedbackData.paginationInfo.pageList,
        locations: state.locationList.locations,
        locationMapping: state.locationMappingGroupList.mappings,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {        
        listLocations: (...params) => dispatch(listLocations(...params)),
        findStaffByNameOnly: (...params) => dispatch(findStaffByNameOnly(...params)),
        listAllStaffFeedbackReport: (...params) => dispatch(listAllStaffFeedbackReport(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        listLocationMappingGroups: (...params) => dispatch(listLocationMappingGroups(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrittenStaffFeedbackReport);
